<template>
  <v-container>
    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
    <v-expansion-panels :multiple="true" :accordion="false" :value="expanded">
      <v-expansion-panel v-for="item in messageSubscriptions" :key="item.id" :expand="true">
        <v-expansion-panel-title>
          <span> {{ item.type + ': ' + item.name + (item.type === 'Dairy' ? ', MBH: ' + item.mbhIdent : '') }}</span>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row>
            <v-col cols="3" sm="4" md="3">
              <v-text-field
                id="name"
                :label="$t('messages_subscriptions_default_email')"
                :placeholder="$t('messages_subscriptions_default_email')"
                v-model="item.defaultMail"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table-virtual :items="item.messageSubscriptions" :headers="fields">
            <template v-slot:[`item.edit`]="{ item }">
              <div class="link" @click="openDialog(item)">
                <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon>
              </div>
            </template>
            <template v-slot:[`item.lastActivity`]="{ item }">
              {{ item.lastActivity ? $moment(item.lastActivity).format('DD.MM.YYYY') : '' }}
            </template>
            <template v-slot:[`item.subscribed`]="{ item }">
              <v-switch
                id="messages_subscriptions_subscribed"
                v-model="item.subscribed"
                color="primary"
                :disabled="!item.subscribable"
                v-on:change="subscribe(item)"
              ></v-switch>
            </template>
            <template v-slot:[`item.channel`]="{ item }">
              {{ item.channel ? item.channel.charAt(0) + item.channel.slice(1).toLowerCase() : '' }}
            </template>
            <template v-slot:[`item.receivers`]="{ item }"> {{ formatReceivers(item.receivers) }} </template>
          </v-data-table-virtual>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    <!--  Dialog Detail  -->
    <v-dialog :retain-focus="false" max-width="60%" id="subscription-detail-dialog" v-model="dialog">
      <v-card>
        <v-card-title>
          {{ subscriptionDetails.messageTypeNameDe }}
        </v-card-title>
        <v-card-text>
          <v-form ref="detail">
            <v-row>
              <!-- Kanal -->
              <v-col sm="12" md="4" lg="4">
                <v-text-field
                  id="messages_subscriptions_detail_receiver_channel"
                  v-model="subscriptionDetails.channel"
                  type="text"
                  :label="$t('messages_subscriptions_detail_receiver_channel')"
                  disabled
                />
                <!--   abonniert  -->
                <v-col sm="4" md="8" lg="8">
                  <v-switch
                    id="messages_subscriptions_detail_subscribed"
                    @change="update(subscriptionDetails)"
                    v-model="subscriptionDetails.subscribed"
                    :disabled="subscriptionDetails.subscribable"
                    :label="$t('messages_subscriptions_detail_subscribed')"
                    color="primary"
                  ></v-switch>
                </v-col>
              </v-col>
              <!-- Absender -->
              <v-col sm="12" md="4" lg="4">
                <v-text-field
                  id="messages_subscriptions_detail_absender"
                  v-model="subscriptionDetails.senderName"
                  :label="$t('messages_subscriptions_detail_absender')"
                  disabled
                />
              </v-col>
              <!-- Empfänger Liste -->
              <v-col sm="2" md="4" lg="4">
                <div v-for="receiver in subscriptionDetails.receivers" class="search-inline" v-if="subscriptionDetails?.receivers?.length >= 1">
                  <div class="d-flex">
                    <v-text-field
                      id="messages_subscriptions_detail_receiver"
                      @change="update(subscriptionDetails)"
                      v-model="receiver.email"
                      :label="$t('messages_subscriptions_detail_receiver')"
                      class="padding-right"
                      :rules="subscriptionDetails.channel === 'EMAIL' ? [rules.email, rules.required] : [rules.required]"
                    />
                    <v-btn v-if="subscriptionDetails?.receivers.length > 1" @click="deleteReceiver(receiver)">
                      <v-icon dbmblueprimary>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </div>
                <!-- Neuer Empfänger -->
                <v-form ref="newReceiver" @submit.prevent="addReceiver(newReceiver)">
                  <div class="d-flex" v-if="validatePermissionToAddSMSorEmail(subscriptionDetails)">
                    <v-text-field
                      id="messages_subscriptions_detail_receiver_next"
                      v-model.trim="newReceiver"
                      :label="$t('messages_subscriptions_detail_receiver_next')"
                      :rules="subscriptionDetails.channel === 'EMAIL' ? [rules.email] : null"
                    />
                    <v-btn type="submit" :loading="loadingAddSubscription">
                      <v-icon dbmblueprimary>mdi-content-save-outline</v-icon>
                    </v-btn>
                  </div>
                </v-form>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-text>
          <!-- History-->
          <v-col sm="12" class="item card-spacing">
            <rdhComponent
              :show="$privileges.has({ path: '/masterData', permission: 'showHistorySpecialDomainSwitch' })"
              :remarks_show="false"
              :documents_show="false"
              :history_show="true"
              :history_itemperpage="5"
              :history_url="'/messageSubscriptions/' + subscriptionDetails.id"
              ref="rdh"
              documents_tag=""
            ></rdhComponent>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click="closeDialog()">
            <span v-html="$t('messages_subscriptions_detail_cancel')" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { apiURL, emitter } from '@/main'
import { showError } from '@/services/axios'
import { readonly } from 'vue'
import rdhComponent from '@/components/rdh/rdhComponent.vue'
import { privileges } from '@/services/privileges'

export default {
  name: 'message_subscriptions_read',
  data() {
    return {
      items: [],
      loading: false,
      loadingUpdateSubscription: false,
      loadingAddSubscription: false,
      showDialog: false,
      hover: false,
      messageSubscriptions: [],
      dialog: false,
      subscriptionDetails: {},
      expanded: [],
      newReceiver: '',
      rules: {
        required: (value) => !!value || this.$t('form_field_required'),
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return !value || pattern.test(value) || this.$t('form_field_invalid_email')
        }
      },
      privileges: privileges,
      fields: [
        {
          value: 'edit',
          sortable: false,
          width: '2%'
        },
        {
          title: this.$t('messages_subscriptions_campaign_group'),
          value: 'messageTypeName' + this.$route.params.upLang, // temp. solution, TBD as well as subject
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('messages_subscriptions_campaign'),
          value: 'campaign',
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('messages_subscriptions_channel'),
          value: 'channel',
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('messages_subscriptions_sender'),
          value: 'senderName',
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('messages_subscriptions_receiver'),
          value: 'receivers',
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('messages_subscriptions_last_activity'),
          value: 'lastActivity',
          sortable: true,
          align: 'false'
        },
        {
          title: this.$t('messages_subscriptions_subscribed'),
          value: 'subscribed',
          sortable: true,
          width: '2%'
        }
      ]
    }
  },
  components: { rdhComponent },
  methods: {
    readonly,
    async load() {
      this.loading = true
      try {
        const response = await this.axios.get(apiURL + '/messageSubscriptions')
        this.messageSubscriptions = await response.data
        this.expandAllPanels(this.messageSubscriptions)
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    async update(messageSubscription) {
      this.loadingUpdateSubscription = true

      if (messageSubscription.receivers !== null) {
        if (!(await this.$refs.detail.validate()).valid || messageSubscription?.receivers?.length === 0) {
          this.loadingUpdateSubscription = false
          return null
        } else {
          await this.updateSubscriptions(messageSubscription)
        }
      }
    },
    async updateSubscriptions(messageSubscription) {
      try {
        this.setReceiverAddress1()
        const result = this.axios.patch(
          `/api/messageSubscriptions/${messageSubscription.id}`,
          {
            ...messageSubscription
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          this.$refs.rdh.reloadHistory()
          this.newReceiver = null
          return (await result).data
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loadingUpdateSubscription = false
      }
    },
    expandAllPanels(panels) {
      this.expanded = panels.map((_, index) => index)
    },
    subscribe(subscription) {
      this.updateSubscriptions(subscription)
    },
    openDialog(item) {
      this.subscriptionDetails = item
      this.dialog = true
    },
    closeDialog() {
      this.newReceiver = ''
      this.dialog = false
    },
    setReceiverAddress1() {
      if (
        this.subscriptionDetails.receiverAddress1 == null ||
        this.subscriptionDetails.receiverAddress1 === '' ||
        this.subscriptionDetails.receivers[0].email !== this.subscriptionDetails.receiverAddress1
      ) {
        this.subscriptionDetails.receiverAddress1 = this.subscriptionDetails.receivers ? this.subscriptionDetails.receivers[0].email : null
      }
    },
    deleteReceiver(receiver) {
      this.subscriptionDetails.receivers = this.subscriptionDetails.receivers.filter((item) => item.email !== receiver.email)
      this.update(this.subscriptionDetails)
    },
    async addReceiver(newReceiver) {
      if (this.subscriptionDetails.channel === 'EMAIL') {
        if (!(await this.$refs.newReceiver.validate()).valid) return
      }
      if (!newReceiver) {
        return false
      } else {
        this.subscriptionDetails.receivers.push({ fullName: '', email: newReceiver, id: '', contactId: '' })
        this.newReceiver = ''
        this.update(this.subscriptionDetails) // new todo
      }
    },
    validatePermissionToAddSMSorEmail(subscriptionDetails) {
      // limit is set to 9 emails/SMS
      if (subscriptionDetails?.receivers?.length > 8) {
        return false
      }
      // SMS case
      if (subscriptionDetails.channel === 'SMS') {
        const hasWriteAllPermission = privileges.has({
          path: '/messageSubscriptions/smsReceivers',
          permission: 'writeAll'
        })
        const hasWriteOnePermission = privileges.has({
          path: '/messageSubscriptions/smsReceivers',
          permission: 'writeOne'
        })
        const receiversCount = subscriptionDetails?.receivers.length
        // between 1 and 9 SMS - only tsm
        if (receiversCount > 0 && receiversCount <= 9 && hasWriteAllPermission) {
          return true
        }
        // between 0 SMS
        if (receiversCount < 1 && hasWriteOnePermission) {
          return true
        }
        return false
      }
      return true
    },
    formatReceivers(receivers) {
      return receivers.map((receiver) => (receiver.email.length > 20 ? receiver.email.substring(0, 20) + '...' : receiver.email)).join(', ')
    }
  },
  mounted() {
    this.load()
  }
}
</script>
